import { environment } from './../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { LatLngLiteral, Geokit } from 'geokit';


@Injectable({
    providedIn: 'root'
})
export class PlaceService {

    features: any[] = new Array();

    get baseUrl(): string {
        return 'https://www.badkartan.se/ajax.php?action=manually_load_venues';
    }

    get apiUrl(): string {
        return environment.baseUrl;
    }

    constructor(private httpClient: HttpClient) {
    }

    getFromRaven(center: LatLngLiteral) {
        return this.httpClient.get(`${this.apiUrl}/bathplaces/50/${center.lng}/${center.lat}`);
    }

    getGoogleDetails(placeId: string) {
        return this.httpClient.get(`${this.apiUrl}/googledetails/${placeId}`);
    }
    getGooglePlaceId(id: string, name: string) {
        return this.httpClient.get(`${this.apiUrl}/bathplaces/${id}/${name}`);
    }
    getGoogleReviews(id: string) {
        return this.httpClient.get(`${this.apiUrl}/reviews/${id}`);
    }

    setFromRaven(center: LatLngLiteral) {
        this.getFromRaven(center).subscribe((features: any[]) => {
            this.features = features;
        });
    }

    geoPointToLatLngLiteral(geoPoint: firebase.firestore.GeoPoint): LatLngLiteral {
        return { lat: geoPoint.latitude, lng: geoPoint.longitude } as LatLngLiteral;
    }

    getDistance(center: LatLngLiteral, coordinates: LatLngLiteral) {
        return Geokit.distance(center, coordinates);
    }

}
