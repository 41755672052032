
import { Component, OnInit, ViewChild } from '@angular/core';
import { PlaceService } from './home.service';
import { LottieOptions, AnimationItem, BMEnterFrameEvent } from 'ngx-lottie';
import { GeoService } from './geo.service';
import { AgmMap, LatLngBounds } from '@agm/core';
import * as firebase from 'firebase/app';
import { Geokit, LatLngLiteral } from 'geokit';
import * as geofirex from 'geofirex';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { trigger, style, transition, animate, group } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('info', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
        animate('400ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('400ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {


  public options: LottieOptions = {
    path: '/assets/rating.json',
    loop: false,
    autoplay: true,
    autoloadSegments: false
  };
  lat = 59.1431910345876;
  lng = 15.392627302835272;



  selectedMarker;
  markers = [];


  get features() {
    return this.placeService.features;
  }

  title = 'Badkartan';
  animationItem: AnimationItem;
  protected map: AgmMap;
  northEast: firebase.firestore.GeoPoint;
  southWest: firebase.firestore.GeoPoint;
  center: LatLngLiteral;
  centerHash: string;
  endHash: string;
  geo: geofirex.GeoFireClient;
  extraInfo: { photos: any; reviews: any; rating: any; };


  markers$: Observable<any>;
  collection: geofirex.GeoFireCollectionRef;

  constructor(private placeService: PlaceService) { }

  ngOnInit() {
    this.getUserLocation();
  }

  mapReady(map) {
    this.map = map;
  }

  centerChange(latLngLiteral: LatLngLiteral) {
    this.center = latLngLiteral;
  }

  idle() {
    if (!this.center) {
      return;
    }
    this.placeService.setFromRaven(this.center);
  }

  private getUserLocation() {
    /// locate the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }
  public animationCreated(animationItem: AnimationItem, rating): void {
    this.animationItem = animationItem;
    if (rating < 2) {
      return;
    }
  }

  public enterFrame(animationItem: BMEnterFrameEvent, marker: any): void {
  }

  domLoaded(extraInfo) {
    this.animationItem.playSegments([0, this.getFrame(extraInfo)], true);
  }

  getFrame(extraInfo) {

    const percentageMarker = (extraInfo.rating * 2) / 10;
    const percentageLottie = 90 * percentageMarker;


    for (let index = 5; index > 0; index--) {
      if (percentageLottie > 18 * (index)) {
        return 15 * (index + 1);
      }
    }
    return 14;
  }

  addMarker(marker: any) {
    this.markers.push(marker);
  }

  max(coordType: 'lat' | 'lng'): number {
    return Math.max(...this.markers.map(marker => marker[coordType]));
  }

  min(coordType: 'lat' | 'lng'): number {
    return Math.min(...this.markers.map(marker => marker[coordType]));
  }

  async selectMarker(event, feature) {
    this.extraInfo = { photos: [], reviews: [], rating: 0 };
    try {
      if (!feature.placeId) {
        feature = await this.placeService.getGooglePlaceId(feature.id, feature.name).toPromise();
      }
      const extraInfo: any = await this.placeService.getGoogleDetails(feature.placeId).toPromise();
      this.extraInfo = { photos: [], reviews: extraInfo.reviews, rating: extraInfo.rating };

      const reviews: any = await this.placeService.getGoogleReviews(feature.placeId).toPromise();
      this.extraInfo.reviews = reviews;
      this.selectedMarker = feature;
    } catch (error) {
      this.selectedMarker = feature;
    }

  }

  // async getExtraInfo(feature) {
  //   this.extraInfo = await this.geoService.getExtraInfo(feature.name);
  // }

  mapClick() {
    this.selectedMarker = undefined;
  }
}
